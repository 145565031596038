export const watch = {
  currentHoaId(hoaID) {
    console.debug(hoaID)
    this.getList()
  },
  currentUnitId(unitID) {
    console.debug(unitID)
    this.getList()
  },
  currentOwnerId() {
    this.getList()
  },
  requestsFilter: 'filterRequestRows',
  toggle() {
    if (this.toggle == false) {
      this.reload()
      this.toggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
    }
  }
}
