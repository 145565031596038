<template>
  <div question-answer-modal>
    <div class="title" v-if="surveyHeader">{{ surveyHeader.name }}</div>
    <div v-if="selectedQuestion" class="container">
      <div v-if="completed" class="subtitle font-bold">
        Press "Complete" to finish the survey.
      </div>
      <div v-else class="subtitle font-bold">
        {{ `${selectedQuestion.counter}. ${selectedQuestion.name || ''}` }}
      </div>
      <div v-if="selectedQuestion.questionOptions && !completed">
        <div v-for="(c, index) in selectedQuestion.questionOptions" :key="index">
          <div v-if="c.content">
            <span v-if="selectedQuestion.questionType.codeName == 'radiobutton'">
              <b-radio v-model="radioAnswer" name="radioAnswer" :native-value="c.questionOptionID">
                {{ c.content }}
              </b-radio>
            </span>
            <span v-else-if="selectedQuestion.questionType.codeName == 'text'">
              <span class="subtitle">{{ c.content }}</span>
              <valid-input
                style="width:50px !important;"
                name="AnswerComment"
                label="Answer"
                maxlength="8000"
                type="textarea"
                vid="AnswerComment"
                placeholder="Note"
                spellcheck="true"
                aria-label="Note"
                rules="max:8000|required"
                class="is-small"
                v-model="answerComment"
              />
              <b-input
                name="answerCommentQuestionID"
                id="answerCommentQuestionID"
                type="hidden"
                :value="c.questionOptionID"
              />
            </span>
            <span class="pt-5" v-else-if="selectedQuestion.questionType.codeName == 'scale'">
              <b-slider
                v-model="sliderAnswer"
                class="pt-5"
                tooltip-always
                size="is-large"
                :min="c.scaleMin"
                :max="c.scaleMax"
                :value="c.selectedScale"
              >
                <template v-for="val in scaleTicks">
                  <b-slider-tick :value="val" :key="val">{{ val }}</b-slider-tick>
                </template>
              </b-slider>
              <b-input
                name="answerScaleQuestionID"
                id="answerScaleQuestionID"
                type="hidden"
                :value="c.questionOptionID"
              />
            </span>
            <span v-if="selectedQuestion.questionType.codeName == 'checkbox'">
              <b-checkbox v-model="checkboxGroup" :native-value="c.questionOptionID">
                {{ c.content }}
              </b-checkbox>
            </span>
          </div>
        </div>
        <div class="pt-4">
          <span v-if="currentQuestionNumber > 1" class="pr-5">
            <b-button @click.prevent="previousQuestion()" size="is-primary">
              Prev
            </b-button>
          </span>
          <span>
            <b-button :required="true" @click.prevent="submitAnswer()" size="is-primary">
              Next
            </b-button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="completed" class="pt-4">
      <span class="pr-5">
        <b-button @click.prevent="previousQuestion()" size="is-primary">
          Prev
        </b-button>
      </span>
      <span>
        <b-button @click.prevent="completeSurvey()" size="is-primary">
          Complete
        </b-button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    surveyHeader: Object
  },

  components: {
    ValidInput
  },

  created() {
    this.firstLoad = true
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
