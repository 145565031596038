/*
  import { workOrderRequestStore } from '@/services/WorkOrderRequests/WorkOrderRequest/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async cancelWorkOrderRequest({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in cancelWorkOrderRequest')
    try {
      const results = await kms.post(
        `/WorkOrderRequests/WorkOrderRequest/CancelRequest?workOrderRequestID=${payload.workOrderRequestID}`,
        {
          cancelReason: payload.cancelReason,
          workOrderRequestID: payload.workOrderRequestID
        }
      )

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.workOrderRequestID > 0) {
        notifyMessage(`Successfully cancelled this work order request.`)
        done()
      } else {
        notifyError('There was a problem cancelling this work order request.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem cancelling this work order request.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const workOrderRequestStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
