/*
  import { surveyQuestionList } from '@/services/Surveys/HomeOwner/Questions/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { surveyQuestionList } = await surveyHoList.dispatch ('getSurveyNotCompletedList', {
      hoaId
    });
  */
  async getQuestions({}, { surveySectionID, surveyHeaderID, filterByIdentity }) {
    console.log('in getQuestions...' + surveyHeaderID)
    try {
      const result = await kms.get('/Surveys/Question/List', {
        params: {
          surveySectionID,
          surveyHeaderID,
          filterByIdentity
        }
      })

      if (isDebug == true) console.debug('getQuestions log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The not completed surveys list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Add Answer
  async addSurveyAnswer(
    { dispatch },
    { questionOptionID, answerComment, selectedScale, deleteFormerAnswers, questionNumber, done }
  ) {
    if (isDebug == true) console.debug('...in addSurvey')
    if (questionOptionID && questionOptionID > 0) {
      try {
        let aspNetUserID = 'stub'
        const results = await kms.post(
          `/Surveys/Answer?deleteFormerAnswers=${deleteFormerAnswers}`,
          {
            aspNetUserID,
            questionOptionID,
            answerComment,
            selectedScale
          }
        )

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        if (results.answerID > 0) {
          if (isDebug == true) console.debug(`Saved question number: ${questionNumber}.`)
          done({ details: results })
        } else {
          notifyError('There was a problem adding this answer.')
        }
      } catch (exception) {
        notifyProblem(`There was a problem adding this answer.`)
        console.error(exception)
      }
    }
  },

  // Add Multiple Answers
  async addSurveyAnswers(
    { dispatch },
    { deleteFormerAnswers, questionNumber, questionID, payload = {}, done }
  ) {
    if (isDebug == true) console.debug('...in addSurveyAnswers')
    if (payload) {
      try {
        const results = await kms.post(
          `/Surveys/Answer/CreateMultipleAnswers?questionID=${questionID}&deleteFormerAnswers=${deleteFormerAnswers}`,
          payload
        )

        if (this.isDebug == true)
          console.debug('addSurveyAnswers results=' + JSON.stringify(results))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        if (results && results == true) {
          if (isDebug == true) console.debug(`Saved question number: ${questionNumber}.`)
          done({ details: results })
        } else {
          notifyError('There was a problem adding this answer.')
        }
      } catch (exception) {
        notifyProblem(`There was a problem adding this answer.`)
        console.error(exception)
      }
    }
  },

  // Complete Survey
  async completeSurvey({ dispatch }, { surveyHeaderID, aspNetUserID, done }) {
    if (isDebug == true) console.debug('...in addSurvey')
    if (surveyHeaderID && surveyHeaderID > 0) {
      try {
        aspNetUserID = 'stub'
        const result = await kms.post(`/Surveys/SurveyComplete`, {
          surveyHeaderID,
          aspNetUserID
        })

        if (this.isDebug == true) console.debug('survey complete results=' + JSON.stringify(result))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        if (result.surveyCompleteID > 0) {
          notifyMessage(`Sucessfully completed the survey.`)
          done({ details: result })
        } else {
          notifyError('There was a problem completing this survey.')
        }
      } catch (exception) {
        notifyProblem(`There was a problem completing this survey.`)
        console.error(exception)
      }
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const surveyQuestionList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
