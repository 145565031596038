<template>
  <PageContentLayoutOnly :sectionStyles="styles.pageContentSection">
    <div class="box tile is-vertical is-12">
      <div class="level">
        <span class="title pt-2 pl-2">User Settings</span>
      </div>

      <LinksMenu aria-label="account settings tabs" :tabs="tabs" prefix="userSettings." :style="{}">
        <section :style="{ overflowX: 'auto', flexGrow: 2 }">
          <SlideTransition :name="transitionName">
            <keep-alive>
              <router-view />
            </keep-alive>
          </SlideTransition>
        </section>
      </LinksMenu>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import { mapState, mapGetters, mapActions } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import SlideTransition from '@/components/transitions/Slide.vue'

import LinksMenu from '@/components/menus/Links'

import { data } from './keys/data'

export default {
  components: {
    PageContentLayoutOnly,
    SlideTransition,
    LinksMenu
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.loading = true
    this.currentAssociation = this.hoaId
    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    authUser() {
      // reset association when user status changes
      this.currentAssociation = this.hoaId
    },

    currentAssociation(hoaId) {
      // pass selected HOA ID to Vuex when user changes it here
      this.selectHoaId(hoaId)
    },

    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    },

    $route: function(to, from) {
      const toIndex = _get(to, ['params', 'index'], 1)
      const fromIndex = _get(from, ['params', 'index'], 1)

      this.transitionName = toIndex < fromIndex ? 'slide-up' : 'slide-down'
    }
  },

  methods: {
    ...mapActions('user', ['selectHoaId', 'userRetrieve'])
  },

  i18n: {
    messages: {
      en: { userSettings: { title: 'User Settings' } }
    }
  }
}
</script>
