/*
  example:
    import { addWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Post';

    const { result, message, successful } = await addWorkOrderRequest ({
      json: {}
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function addWorkOrderRequest({ kms: kms_ = kms, json } = {}) {
  const message = 'The work order requests could not be opened.'

  try {
    const result = await kms_.post(`/WorkOrderRequests/WorkOrderRequest`, json)

    return {
      successful: true,
      result,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    result: null,
    successful: false,
    message
  }
}

///////
