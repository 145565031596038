<template>
  <div>
    <router-link
      class="button is-info is-light is-small is-outline is-rounded"
      :to="detailsLocation"
      :style="{ margin: '4px 8px' }"
    >
      Details
    </router-link>

    <b-button
      class="button is-info is-light is-small is-outline is-rounded"
      v-if="isCancelHidden === false"
      @click="cancel"
      rounded
      :style="{ margin: '4px 8px' }"
      >Cancel</b-button
    >

    <b-button
      @click="askaquestion"
      class="button is-info is-light is-small is-outline is-rounded"
      :style="{ margin: '4px 8px' }"
      >Ask a Question</b-button
    >
  </div>
</template>

<script>
import _get from 'lodash/get'
//
export default {
  props: {
    workOrderRequestID: Number,
    edit: Function,
    cancel: Function,
    askaquestion: Function,
    isCancelHidden: Boolean
  },

  computed: {
    detailsLocation() {
      const id = _get(this, 'workOrderRequestID', '')

      return `/workorderpromotetabs/${id}/workorderRequestDetail#tab`
    }
  }
}
</script>
