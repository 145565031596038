import { surveyQuestionList } from '@/services/Surveys/HomeOwner/Questions/store'
import { notifyError } from '@/services/notify'
import $ from 'jquery'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    console.debug('reload()=' + JSON.stringify(this.surveyHeader))

    if (_get(this, 'surveyHeader.surveyHeaderID', null) > 0) {
      await this.loadCurrentQuestion()
      console.debug('this.selectedQuestion=' + JSON.stringify(this.selectedQuestion))
    }

    this.loading = false
  },

  open() {
    this.loading = false
  },

  async loadCurrentQuestion() {
    await surveyQuestionList
      .dispatch('getQuestions', {
        surveySectionID: null,
        surveyHeaderID: this.surveyHeader.surveyHeaderID,
        filterByIdentity: true
      })
      .then(({ list }) => {
        let counter = 1
        if (this.isDebug == true) console.debug('in loadCurrentQuestion done list...')
        if (list) {
          this.questions = list.map(m => ({
            ...m,
            counter: counter++
          }))

          let temp = this.questions.filter(f => f.counter == this.currentQuestionNumber)

          if (this.firstLoad == true) {
            if (
              this.questions[0] &&
              this.questions[0].maxQuestionIdFromLastAnswered &&
              this.questions[0].maxQuestionIdFromLastAnswered != undefined &&
              temp[0] &&
              temp[0].questionID != this.questions[0].maxQuestionIdFromLastAnswered
            ) {
              temp = this.questions.filter(f => f.maxQuestionIdFromLastAnswered == f.questionID)

              if (this.isDebug == true) console.debug('temp first load=' + JSON.stringify(temp))

              this.firstLoad = false

              if (temp[0]) {
                this.currentQuestionNumber = temp[0].counter
                if (this.isDebug == true) console.debug('temp curent question=' + temp[0].counter)
              }
            }
          }

          if (this.firstLoad == true) {
            this.firstLoad = false
          }

          if (this.isDebug == true)
            console.debug('question filter=' + JSON.stringify(this.questions))

          if (temp && temp[0]) {
            this.selectedQuestion = temp[0]

            this.checkboxGroup = []
            this.answerComment = ''
            this.answerCommentQuestionID = 0
            this.radioAnswer = 0
            this.answerScaleQuestionID = 0
            this.sliderAnswer = 0

            var currentQuestionOptions = this.selectedQuestion.questionOptions

            if (currentQuestionOptions) {
              Array.from(currentQuestionOptions).forEach(f => {
                if (f) {
                  if (this.selectedQuestion.questionType.codeName == 'scale') {
                    this.scaleTicks = []
                    for (let i = f.scaleMin; i <= f.scaleMax; i++) {
                      this.scaleTicks.push(i)
                    }
                  }

                  var answers = f.answers
                  if (answers) {
                    Array.from(answers).forEach(a => {
                      if (a) {
                        if (this.selectedQuestion.questionType.codeName == 'text') {
                          this.answerComment = a.answerComment
                          this.answerCommentQuestionID = a.questionOptionID
                        }

                        if (this.selectedQuestion.questionType.codeName == 'radiobutton') {
                          this.radioAnswer = a.questionOptionID
                        }

                        if (this.selectedQuestion.questionType.codeName == 'scale') {
                          this.sliderAnswer = a.selectedScale
                          this.answerScaleQuestionID = a.questionOptionID
                        }

                        if (this.selectedQuestion.questionType.codeName == 'checkbox') {
                          this.checkboxGroup.push(a.questionOptionID)
                        }
                      }
                    })
                  }
                }
              })
            }

            if (
              this.selectedQuestion &&
              this.selectedQuestion.questionOptions &&
              this.selectedQuestion.questionOptions != undefined &&
              this.selectedQuestion.questionOptions.answers &&
              this.selectedQuestion.questionOptions.answers != undefined
            ) {
              this.radioAnswer = this.selectedQuestion.questionOptions[0].answers[0].questionOptionID
            }
          } else {
            this.completed = true
          }
        }
        this.loading = false
      })
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.questionOptionToggle = false))
    $('#clickclose').click()
    this.loading = false
  },

  async previousQuestion() {
    this.completed = false
    this.currentQuestionNumber = this.currentQuestionNumber - 1

    if (this.isDebug == true) console.debug('previousQuestion...' + this.currentQuestionNumber)

    await this.reload()
  },

  resetAnswerVars() {
    if (this.typeCodeName != 'test') {
      this.answerComment = ''
      this.answerCommentQuestionID = 0
    }

    if (this.typeCodeName != 'radiobutton') {
      this.radioAnswer = 0
    }

    this.typeCodeName = ''
    return
  },

  async submitAnswer() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.selectedQuestion.questionType.codeName == 'radiobutton') {
      if (!this.radioAnswer || this.radioAnswer == 0) {
        notifyError('Please make a selection.')
        loadingComponent.close()
        return
      }

      this.answerComment = ''
      this.answerCommentQuestionID = 0
      this.answerScaleQuestionID = 0
      this.sliderAnswer = 0
      this.checkboxGroup = []
      this.typeCodeName = 'radiobutton'

      if (this.isDebug == true) console.debug('radio answer=' + this.radioAnswer)

      await this.addAnswer(this.radioAnswer, '', 0, true, true)
    } else if (this.selectedQuestion.questionType.codeName == 'text') {
      this.radioAnswer = 0
      this.typeCodeName = 'text'
      this.sliderAnswer = 0
      this.answerScaleQuestionID = 0
      this.checkboxGroup = []

      if (this.isDebug == true)
        console.debug(
          'text answer=' + this.answerComment + ', ' + $('#answerCommentQuestionID').val()
        )

      await this.addAnswer($('#answerCommentQuestionID').val(), this.answerComment, 0, true, true)
    } else if (this.selectedQuestion.questionType.codeName == 'scale') {
      if (this.isDebug == true)
        console.debug(
          'scale answer=' + this.sliderAnswer + ', ' + $('#answerScaleQuestionID').val()
        )

      this.radioAnswer = 0
      this.typeCodeName = 'scale'
      this.answerComment = ''
      this.answerCommentQuestionID = 0
      this.checkboxGroup = []
      await this.addAnswer($('#answerScaleQuestionID').val(), '', this.sliderAnswer, true, true)
    } else if (this.selectedQuestion.questionType.codeName == 'checkbox') {
      if (this.isDebug == true)
        console.debug('checkbox answers=' + JSON.stringify(this.checkboxGroup))

      this.radioAnswer = 0
      this.typeCodeName = 'scale'
      this.answerComment = ''
      this.answerCommentQuestionID = 0

      if (this.checkboxGroup && this.checkboxGroup.length > 0) {
        let deleteFormerAnswersCheckBox = true

        let results = []
        Array.from(this.checkboxGroup).forEach(async f => {
          if (f) {
            if (this.isDebug == true)
              console.debug(deleteFormerAnswersCheckBox + ' checkboxGroup add=' + f)
            results.push({ AspNetUserID: 'stub', QuestionOptionID: f })
          }
        })

        const payload = {
          results: results
        }

        await this.addAnswers(deleteFormerAnswersCheckBox, payload, true)
      }
    }

    loadingComponent.close()
  },

  async processToNextQuestion() {
    if (this.isDebug == true)
      console.debug('in processToNextQuestion...' + this.currentQuestionNumber)
    const previousQuestionNumber = this.currentQuestionNumber
    this.currentQuestionNumber++

    if (previousQuestionNumber == this.currentQuestionNumber) {
      this.completed = true
      if (this.isDebug == true) console.debug('completed=true')
    } else {
      await this.reload()
    }
  },

  async addAnswers(deleteFormerAnswers, payload, nextQuestion) {
    if (!payload) {
      notifyError('There was a problem processing this answer.')
      return
    }

    this.loading = true

    await surveyQuestionList.dispatch('addSurveyAnswers', {
      deleteFormerAnswers: deleteFormerAnswers,
      questionNumber: this.currentQuestionNumber,
      questionID: this.selectedQuestion.questionID,
      payload: payload,
      done: async () => {
        this.loading = false

        if (nextQuestion == true) {
          await this.processToNextQuestion()
        }
      }
    })
  },

  async addAnswer(questionOptionID, comment, sliderValue, deleteFormerAnswers, nextQuestion) {
    if (questionOptionID <= 0) {
      notifyError('There was a problem processing this answer.')
      return
    }

    this.loading = true

    await surveyQuestionList.dispatch('addSurveyAnswer', {
      questionOptionID: questionOptionID || 0,
      answerComment: comment ? comment : null,
      selectedScale: sliderValue > 0 ? sliderValue : null,
      deleteFormerAnswers: deleteFormerAnswers,
      questionNumber: this.currentQuestionNumber,
      done: async () => {
        this.loading = false

        if (nextQuestion == true) {
          await this.processToNextQuestion()
        }
      }
    })
  },

  async completeSurvey() {
    await surveyQuestionList.dispatch('completeSurvey', {
      surveyHeaderID: this.surveyHeader.surveyHeaderID || 0,
      aspNetUserID: 'getFromServer',
      done: async ({ details }) => {
        this.loading = false

        if (details && details.surveyCompleteID > 0) {
          //redirect back to home page
          this.$router.push({
            path: '/'
          })
        }
      }
    })
  }
}
