import _get from 'lodash/get'
import $ from 'jquery'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import { getOwner } from '@/services/Roster/Owner/getOwner'
import { workOrderList } from '@/services/WorkOrders/store'
import { getWorkOrderReqsList } from '@/services/WorkOrderRequests/WorkOrderRequest/List'
import { addWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Post'
import { editWorkOrderRequest } from '@/services/WorkOrderRequests/WorkOrderRequest/Put'
import { getWorkOrderTypes } from '@/services/WorkOrders/WorkOrderType/List'
import { addWorkORPhoto } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/Post'
import { workOrderRequestStore } from '@/services/WorkOrderRequests/WorkOrderRequest/store'
import { getPhoto } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/GetPhotoFile'
import { hoaUtility } from '@/services/Hoa/store'
import { StoreMultiplePhotos } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/procedures/StoreMultiplePhotos'
import { parseDate } from '@/utilities/Date/parse'
import Actions from '../components/Actions'
import { workOrderRequestStatus } from '@/utilities/workOrders/request/status'
import moment from 'moment'
import _orderBy from 'lodash/orderBy'

export const methods = {
  hideShowMap() {
    console.debug('in hideShowMap...')
    this.hideShowMapFlag = !this.hideShowMapFlag

    if (this.hideShowMapFlag == true) {
      this.hideShowMapText = 'Hide Map'
    } else {
      this.hideShowMapText = 'Show Map'
    }
  },

  filterRequestRows() {
    if (this.isDebug === true) console.debug('...filterRequestRows()=' + this.requestsFilter)

    const requestsFilter = this.requestsFilter.toLowerCase()

    this.rowsFiltered = this.rows.filter(row => {
      const status = _get(row, ['status', 'props', 'text'], '').toLowerCase()

      if (requestsFilter === 'pending') {
        return status === 'pending'
      } else if (requestsFilter === 'promoted') {
        return status === 'promoted'
      } else if (requestsFilter === 'cancelled') {
        return status === 'cancelled'
      } else if (requestsFilter === 'rejected') {
        return status === 'rejected'
      } else if (requestsFilter === 'all') {
        return true
      }

      return status === 'pending'
    })
  },

  async populateLocationTypes() {
    await workOrderList.dispatch('loadLocationTypes').then(({ list }) => {
      if (list) {
        this.locationTypeList = list
      }
    })
  },

  async getList() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await this.populateLocationTypes()

    this.name = _get(this, ['$store', 'state', 'user', 'authUser', 'name'], null)
    const ownerID = this.currentOwnerId
    if (typeof ownerID !== 'number') {
      console.warn(
        'ownerID is not a number, the portal has probably not yet computed the ownerID',
        { ownerID }
      )
      loadingComponent.close()
      return
    }

    const { exception } = await getOwner({
      ownerID
    })
    if (exception) {
      console.debug(exception)
      return
    }

    const { list, message, successful } = await getWorkOrderReqsList({
      params: {
        hoaId: this.currentHoaId,
        sourceUnitID: this.currentUnitId
      }
    })
    if (!successful) {
      console.debug(message)
      return
    }

    this.rows = []
    for (let a = 0; a < list.length; a++) {
      const entry = list[a]

      const submitterOwnerID = _get(entry, 'sourceUnit.primaryOwnerID', 0)
      const submitterName = _get(entry, 'submitterName', '')
      const workOrderRequestID = _get(entry, 'workOrderRequestID', 0)

      const statusText = _get(entry, 'status', '')
      const { status } = workOrderRequestStatus({ statusText })
      if (statusText.toLowerCase() === 'pending') {
        isHidden = false
      }
      let isHidden = false
      try {
        if (statusText.toLowerCase() === 'pending') {
          isHidden = false
        }
      } catch (x) {
        console.error(statusText)
        console.error(x)
      }

      let date = _get(entry, 'createdDate', '')

      try {
        date = moment(date).format('LL')
      } catch (exception) {
        console.error(exception)
      }

      this.rows.push({
        category: _get(entry, 'workOrderType.name', ''),
        description: _get(entry, 'description', ''),
        detailedDescription: _get(entry, 'detailedDescription', ''),

        submittedByAddress: _get(entry, 'sourceUnit.address.addressOne', ''),
        submittedBy: submitterName,

        date: {
          date,
          format: 'LL'
        },

        status,

        actions: {
          component: Actions,
          props: {
            workOrderRequestID: _get(entry, 'workOrderRequestID', ''),
            edit: () => {
              this.redirectToDetails(_get(entry, 'workOrderRequestID', 0))
            },
            cancel: () => {
              this.confirmCancelRequest({
                entry
              })
            },
            isCancelHidden: isHidden,
            askaquestion: () => {
              this.redirectToAskQuestion(workOrderRequestID, submitterOwnerID, submitterName)
            }
          }
        }
      })
    }

    this.filterRequestRows()
    this.tableLoading = false

    loadingComponent.close()
  },

  redirectToAskQuestion(workOrderRequestID, submitterOwnerID, submitterName) {
    this.$router.push({
      name: 'newconversation',
      params: {
        workorderrequestid: workOrderRequestID,
        submitterid: submitterOwnerID,
        submitterlabel: `Add Homeowner - ${submitterName || ''}`
      }
    })
  },

  async getCategories() {
    if (this.categories.length >= 1) {
      return
    }

    const { list, message, successful } = await getWorkOrderTypes()
    if (!successful) {
      console.error(message)
      return
    }
    this.categories = list.map(entry => ({
      label: entry.name,
      value: entry.workOrderTypeID
    }))

    this.categories = _orderBy(this.categories, 'label')
  },

  // this probably isn't used.
  async uploadPhoto() {
    const { message, successful } = await addWorkORPhoto({
      json: {}
    })
    if (!successful) {
      console.error(message)
      return
    }
  },

  async reloadParentImages(addedFile) {
    if (this.isDebug === true) console.debug('Homeowner reloadParentImages...' + addedFile)

    this.addFile = addedFile
    notifyMessage('Your marker image is available in the preview below.')
  },

  async adderPhotosUpdated({ items }) {
    this.adder.photos = items
  },

  updatePreviews({ items }) {
    if (!Array.isArray(items)) {
      console.error('previews could not be updated')
      return
    }

    return items.map(item => {
      console.log('item=' + item)
      return URL.createObjectURL(item)
    })
  },

  async openAdder() {
    this.adder = {
      workOrderTypeID: '',
      workOrderTypeIDMessage: '',
      description: '',
      descriptionMessage: '',
      locationType: '',
      detailedMessage: '',
      detailedDescriptionMessage: '',
      photos: [],
      previews: [],
      locationTypeMessage: '',

      modalLoading: false
    }

    await workOrderList
      .dispatch('getAccessToken', {
        credentialsName: 'google-maps-api-key'
      })
      .then(({ result }) => {
        if (result) {
          this.accessToken = result.credentialValue
        }
      })

    await this.loadHoaBoundsCoords()
    this.latitude = this.defaultCenterLat
    this.longitude = this.defaultCenterLng

    await this.getCategories()

    this.$forceUpdate()

    this.showAdder = true
  },

  async loadHoaBoundsCoords() {
    await hoaUtility
      .dispatch('getHoaById', {
        hoaID: this.currentHoaId
      })
      .then(({ result }) => {
        if (
          result &&
          result.northmostLatitude &&
          result.southmostLatitude &&
          result.eastmostLongitude &&
          result.westmostLongitude
        ) {
          this.defaultCenterLat = (result.northmostLatitude + result.southmostLatitude) / 2
          this.defaultCenterLng = (result.eastmostLongitude + result.westmostLongitude) / 2
        }
      })
  },

  async makeRequest() {
    /*
        
    */

    this.adder.modalLoading = true
    this.$forceUpdate()

    const { workOrderTypeID, description, detailedDescription } = this.adder

    let required = []
    let proceed = true

    if (typeof workOrderTypeID !== 'number' || workOrderTypeID <= 0) {
      this.adder.workOrderTypeIDMessage = 'This field is required'
      proceed = false
      required.push('category')
    } else {
      this.adder.workOrderTypeIDMessage = ''
    }

    if (typeof description !== 'string' || description.length === 0) {
      this.adder.descriptionMessage = 'This field is required'
      proceed = false
      required.push('summary')
    } else {
      this.adder.descriptionMessage = ''
    }

    if (typeof detailedDescription !== 'string' || detailedDescription.length === 0) {
      this.adder.detailedDescriptionMessage = 'This field is required'
      required.push('description')
      proceed = false
    } else {
      this.adder.detailedDescriptionMessage = ''
    }

    if (typeof this.locationType !== 'string' || this.locationType.length === 0) {
      this.adder.locationTypeMessage = 'This field is required'
      required.push('location type')
      proceed = false
    } else {
      this.adder.locationTypeMessage = ''
    }

    if (!proceed) {
      this.adder.modalLoading = false

      this.$forceUpdate()

      let errorMessages = ''
      if (required.length > 0) {
        Array.from(required).forEach(r => {
          if (errorMessages === '') {
            errorMessages = r
          } else {
            errorMessages += ', ' + r
          }
        })

        notifyError(`The following fields are required: ${errorMessages}`)
      }

      return
    }

    const lat = $('#formLatitude')
      ? $('#formLatitude').text()
      : this.latitude
      ? this.latitude
      : null

    if (!lat || lat === 0 || lat === '') {
      if (this.locationType === 'Common Area') {
        notifyError('Please select a location using the map marker.')
        this.adder.modalLoading = false
        return
      }
    }

    const lng = $('#formLongitude')
      ? $('#formLongitude').text()
      : this.longitude
      ? this.longitude
      : null

    if (!lng || lng === 0 || lng === '') {
      if (this.locationType === 'Common Area') {
        notifyError('Please select a location using the map marker.')
        this.adder.modalLoading = false
        return
      }
    }

    var { result, message, successful } = await addWorkOrderRequest({
      json: {
        hoaID: this.currentHoaId,
        sourceUnitID: this.currentUnitId,
        submitterName: this.name,
        submitterType: 'home owner',
        submitterAspNetUserID: 'temp',
        status: 'pending',
        description,
        detailedDescription,
        workOrderTypeID,
        locationType: this.locationType,
        latitude: lat || 0,
        longitude: lng || 0
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    const workOrderRequestID = _get(result, 'workOrderRequestID', '')

    const { problems, message: storageMessage } = await StoreMultiplePhotos({
      photos: this.adder.photos,
      params: {
        workOrderRequestID
      }
    })
    if (problems.length >= 1) {
      notifyProblem(storageMessage)
    }

    this.tableLoading = true
    this.showAdder = false

    await this.getList()
  },

  locationTypeChanged() {
    if (this.locationType === 'Common Area') {
      console.debug('blur locationTypeChanged' + this.locationType)
      this.hideShowMapFlag = true

      if (this.hideShowMapFlag == true) {
        this.hideShowMapText = 'Hide Map'
        this.locationTypeText = 'Location Type - Place marker on map'
      }
    } else {
      this.hideShowMapFlag = false

      if (this.hideShowMapFlag == false) {
        this.hideShowMapText = 'Show Map'
        this.locationTypeText = 'Location Type'
      }
    }
  },

  redirectToDetails(id) {
    this.$router.push({
      path: `workorderpromotetabs/${id}/workorderRequestDetail#tab`,
      params: {
        id: id
      }
    })
  },

  async openEditorModal({ entry }) {
    await this.getCategories()

    const editor = {
      workOrderRequestID: _get(entry, 'workOrderRequestID', ''),
      submitterType: _get(entry, 'submitterType', ''),
      description: _get(entry, 'description', ''),
      workOrderTypeID: _get(entry, 'workOrderTypeID', 0),
      workOrderType: _get(entry, 'workOrderType.name', ''),
      workOrderTypeIDMessage: '',
      detailedDescription: _get(entry, 'detailedDescription', ''),
      detailedDescriptionMessage: '',
      status: _get(entry, 'status', ''),
      createdDate: parseDate(_get(entry, 'createdDate', ''), 'LL'),
      photos: [],
      previews: []
    }

    const photos = _get(entry, ['workOrderRequestPhotos'], [])
    if (Array.isArray(photos)) {
      for (let a = 0; a < photos.length; a++) {
        const workOrderRequestPhotoID = _get(photos, [a, 'workOrderRequestPhotoID'], null)

        const { photo, successful, message } = await getPhoto({
          params: {
            workOrderRequestPhotoID,
            asBase64: true
          }
        })
        if (!successful) {
          console.error(message)
          continue
        }

        editor.photos.push(photo)
      }
    }

    editor.previews = editor.photos

    this.editor = editor
    this.showEditor = true
  },

  async editRequest(entry) {
    this.editor.modalLoading = true
    this.$forceUpdate()

    const { message, successful } = await editWorkOrderRequest({
      json: {
        workOrderRequestID: entry.workOrderRequestID,
        hoaID: this.currentHoaId,
        sourceUnitID: this.currentUnitId,
        submitterName: this.name,
        status: entry.status,
        description: entry.description,
        workOrderTypeID: entry.workOrderTypeID,
        detailedDescription: entry.detailedDescription,
        locationType: entry.locationType,
        latitude: entry.latitude,
        longitude: entry.longitude,
        submitterType: entry.submitterType,
        submitterAspNetUserID: 'temp'
      }
    })
    if (!successful) {
      notifyProblem(message)
      return
    }

    this.editor.modalLoading = false
    this.$forceUpdate()

    this.tableLoading = true
    this.showEditor = false

    await this.getList()
  },

  async confirmCancelRequest({ entry }) {
    const description = _get(entry, 'description', '')
    const id = _get(entry, 'workOrderRequestID')

    this.$buefy.dialog.prompt({
      message: `Are you sure you want to <b>cancel</b> this request for: \n${description}`,
      inputAttrs: {
        placeHolder: 'Cancelation Reason',
        maxlength: 8000
      },
      confirmText: 'Cancel Work Order Request',
      cancelText: 'Close',
      trapFocus: true,
      onConfirm: value => this.cancelRequest(id, value)
    })
  },

  async cancelRequest(id, cancelReason) {
    if (!cancelReason || cancelReason === '') {
      notifyError('Please enter a cancel Reason')
      return
    }

    const payload = {
      workOrderRequestID: id,
      cancelReason: cancelReason
    }

    await workOrderRequestStore.dispatch('cancelWorkOrderRequest', {
      payload,
      done: async () => {
        await this.getList()
      }
    })
  }
}
