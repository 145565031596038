import _get from 'lodash/get'
import { surveyHoList } from '@/services/Surveys/HomeOwner/Welcome/store'

export const methods = {
  async loadSurveyHeader() {
    if (this.isDebug == true) console.debug('in loadSurveyHeader()...')
    await surveyHoList
      .dispatch('getSurveyHeaderById', {
        surveyHeaderID: this.surveyHeaderID
      })
      .then(({ result }) => {
        if (result) {
          this.selectedSurveyHeader = result
        }
      })
  },

  async reload() {
    this.loading = true

    this.surveyHeaderID = _get(this, ['$route', 'params', 'id'], 0)

    if (this.surveyHeaderID && this.authUser) {
      if (this.isDebug == true)
        console.debug('surveyHeaderID=' + JSON.stringify(this.$route.params))
      await this.loadSurveyHeader()
    }

    this.loading = false
  },

  beginSurvey() {
    if (this.isDebug == true) console.debug('in beginSurvey...')
    this.toggle = true
  }
}
