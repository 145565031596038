export function data() {
  return {
    tableLoading: true,
    modalLoading: false,
    isDebug: true,
    rows: [],
    rowsFiltered: [],
    requestsFilter: 'all',
    showAdder: false,
    defaultCenterLat: 0,
    defaultCenterLng: 0,
    workOrderRequestID: -1,
    accessToken: '',
    addFile: null,
    latitude: null,
    longitude: null,
    locationType: '',
    locationTypeText: 'Location Type',
    hideShowMapFlag: false,
    hideShowMapText: 'Show Map',
    locationTypeList: [],
    adder: {
      photos: [],
      workOrderTypeID: 0,
      description: '',
      detailedDescription: '',
      name: '',
      // This field is required
      workOrderTypeIDMessage: '',
      descriptionMessage: '',
      detailedDescriptionMessage: ''
    },
    categories: [],
    showEditor: false,
    editor: {
      photos: [],
      previews: [],
      workOrderTypeID: 0,
      workOrderType: '',
      description: '',
      detailedDescription: '',
      name: '',
      workOrderTypeIDMessage: '',
      descriptionMessge: '',
      detailedDescriptionMessage: '',
      modalLoading: true
    }
  }
}
