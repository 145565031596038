export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    completed: false,
    questions: [],
    scaleTicks: [],
    currentQuestionNumber: 1,
    firstLoad: false,
    radioAnswer: 0,
    scaleAnswer: 0,
    sliderAnswer: 0,
    answerComment: '',
    answerCommentQuestionID: 0,
    answerScaleQuestionID: 0,
    checkboxGroup: [],
    selectedQuestion: null,
    typeCodeName: '',
    styles: {
      formRow: {
        padding: '10px',
        whiteSpace: 'nowrap'
      }
    }
  }
}
