/*
  example:
    import { getWorkOrderReqsList } from '@/services/WorkOrderRequests/WorkOrderRequest/List';

    const { list, message, successful } = await getWorkOrderReqsList ({
      params: {
        hoaId
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
//
export async function getWorkOrderReqsList({ kms: kms_ = kms, params } = {}) {
  const message = 'The work order requests list could not be retrieved.'

  try {
    const result = await kms_.get(`/WorkOrderRequests/WorkOrderRequest/List`, {
      params
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      list,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    list: [],
    successful: false,
    message
  }
}

///////
