/*
  ???
    Call this after 
      add work order request -> workOrderRequestID
      add work order request photo -> workOrderRequestPhotoID
*/

/*
  example:
    import { addWorkORPhoto } from '@/services/WorkOrderRequests/WorkOrderRequestPhoto/Post';

    const { result, message, successful } = await addWorkORPhoto ({
      json: {
        workOrderRequestPhotoID: 0,
        workOrderRequestID: 0,
        documentID: 0
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function addWorkORPhoto({ kms: kms_ = kms, json } = {}) {
  const message = 'The work order requests could not be opened.'

  try {
    const result = await kms_.post(`/WorkOrderRequests/WorkOrderRequest`, json)

    return {
      successful: true,
      result,
      message: ''
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    result: null,
    successful: false,
    message
  }
}

///////
