/*
  import { workOrderRequestStatus } from '@/utilities/workOrders/request/status'

  const { status, text, background } = workOrderRequestStatus ({
    statusText: 'pending'
  });
*/

/*
  Statuses:
  
    Pending
  
    Cancelled

    Rejected

    Promoted
*/

import StatusTile from '@/components/tiles/Status'

export const workOrderRequestStatus = ({ statusText }) => {
  let background = 1
  let text = '?'

  if (statusText.toLowerCase() === 'pending') {
    text = 'Pending'
    background = 1
  } else if (statusText.toLowerCase() === 'cancelled') {
    text = 'Cancelled'
    background = 3
  } else if (statusText.toLowerCase() === 'rejected') {
    text = 'Rejected'
    background = 3
  } else if (statusText.toLowerCase() === 'promoted') {
    text = 'Promoted'
    background = 2
  }

  return {
    text,
    background,
    status: {
      component: StatusTile,
      props: {
        text,
        background
      }
    }
  }
}
