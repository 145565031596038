<template>
  <PageContent :title="$t('workOrderRequestsList.title')">
    <div class="level">
      <div class="level-left">
        <b-button
          make-request
          type="is-primary"
          rounded
          @click="openAdder"
          :style="{ marginBottom: '20px' }"
          >Create Request</b-button
        >
      </div>

      <div class="level-right">
        <b-field label="Requests" tabindex="0" aria-label="work order request filter">
          <b-radio
            v-model="requestsFilter"
            name="name2"
            native-value="all"
            aria-label="show all work order requests"
          >
            All
          </b-radio>
          <b-radio
            v-model="requestsFilter"
            name="name2"
            native-value="pending"
            aria-label="only show pending work order requests"
          >
            Pending
          </b-radio>
          <b-radio
            v-model="requestsFilter"
            name="name2"
            native-value="promoted"
            aria-label="only show promoted work order requests"
          >
            Promoted
          </b-radio>
          <b-radio
            v-model="requestsFilter"
            name="name2"
            native-value="rejected"
            aria-label="only show rejected work order requests"
          >
            Rejected
          </b-radio>
          <b-radio
            v-model="requestsFilter"
            name="name2"
            native-value="cancelled"
            aria-label="only show cancelled work order requests"
          >
            Cancelled
          </b-radio>
        </b-field>
      </div>
    </div>

    <ModernTable
      :columns="[
        {
          field: 'category',
          label: 'Category',
          sortable: true,
          searchable: true
        },
        {
          field: 'description',
          label: 'Title',
          sortable: true,
          searchable: true
        },
        {
          field: 'detailedDescription',
          label: 'Description',
          sortable: true,
          searchable: true
        },

        {
          field: 'submittedByAddress',
          label: 'Submitted By Address',
          sortable: true,
          searchable: true
        },
        {
          field: 'submittedBy',
          label: 'Submitted By',
          sortable: true,
          searchable: true
        },
        {
          field: 'date',
          label: 'Opened On',
          date: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'status',
          label: 'Status',
          width: '15%',
          sortable: false,
          searchable: false
        },
        {
          field: 'actions',
          label: '',
          width: '29%'
        }
      ]"
      :rows="rowsFiltered"
      :filters="{
        show: true
      }"
      :loading="tableLoading"
    />

    <Modal
      work-order-request-creator
      adder-modal
      :toggle.sync="showAdder"
      :styles="{ modalContent: { height: '900px' } }"
      :loading="adder.modalLoading"
    >
      <template v-slot:header>Work Order Request</template>

      <template v-slot:footer>
        <b-button @click="makeRequest" type="is-primary" :style="{ float: 'right' }"
          >Create</b-button
        >
      </template>

      <div class="pb-5">
        <b-field :label="locationTypeText">
          <b-select
            @click.native="locationTypeChanged()"
            v-model="locationType"
            required="true"
            placeholder="Select a Location Type"
          >
            <option v-for="option in locationTypeList" :value="option.value" :key="option.value">
              {{ option.label }}
            </option>
          </b-select>
        </b-field>
      </div>

      <b-field
        label="Category"
        :type="adder.workOrderTypeIDMessage.length >= 1 ? 'is-danger' : ''"
        :message="adder.workOrderTypeIDMessage"
      >
        <b-select
          category-of-request
          v-model="adder.workOrderTypeID"
          required="true"
          placeholder="Select a Category"
        >
          <option v-for="option in categories" :value="option.value" :key="option.value">
            {{ option.label }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Title"
        :type="adder.descriptionMessage.length >= 1 ? 'is-danger' : ''"
        :message="adder.descriptionMessage"
      >
        <b-input summary-of-request type="text" v-model="adder.description"></b-input>
      </b-field>

      <b-field
        label="Description"
        :type="adder.detailedDescriptionMessage.length >= 1 ? 'is-danger' : ''"
        :message="adder.detailedDescriptionMessage"
      >
        <b-input
          description-of-request
          type="textarea"
          v-model="adder.detailedDescription"
        ></b-input>
      </b-field>

      <div @click="hideShowMap" style="cursor: pointer" class="href-overflow-wrap">
        {{ hideShowMapText }}
        <i style="font-size: 8pt; vertical-align: baseline !important;" class="icon-map" />
      </div>
      <div class="font-bold">
        Please drag and drop the pin to represent the location of the work order request.
      </div>
      <div
        class="pt-5 pb-5"
        v-if="hideShowMapFlag && (hideShowMapFlag === true || hideShowMapFlag === 'true')"
      >
        <GoogleMap
          mapStyle="satellite"
          :accessToken="accessToken"
          :longitude="Number.parseFloat(longitude && longitude ? longitude : 0)"
          :latitude="Number.parseFloat(latitude && latitude ? latitude : 0)"
          :zoomLevel="parseInt(19)"
          :workOrderRequestId="parseInt(workOrderRequestID)"
          :reloadParent="reloadParentImages"
        />
      </div>

      <PhotoUploader class="pt-5" label="Photos" :addFile="addFile" :update="adderPhotosUpdated" />
    </Modal>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import PhotoUploader from '@/components/Fields/PhotoUploader'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import GoogleMap from '@/components/googlemaps'
import { computed } from './keys/computed'
import { watch } from './keys/watch'

export default {
  components: {
    Modal,
    ModernTable,
    PageContent,
    PhotoUploader,
    GoogleMap
  },
  data,
  computed,
  methods,
  mounted,
  watch,

  i18n: {
    messages: {
      en: {
        workOrderRequestsList: {
          title: 'Work Order Requests'
        }
      }
    }
  }
}
</script>
