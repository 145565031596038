<template>
  <PageContentLayoutOnly role="region">
    <div class="level-left level-item title">{{ $t('takeSurvey.title') }}</div>
    <div v-if="selectedSurveyHeader">
      <div class="pt-2 pl-1 fontbold">
        <h4>{{ selectedSurveyHeader.name }}</h4>
      </div>
      <div class="pt-2 pl-1">
        <h5>{{ selectedSurveyHeader.header }}</h5>
      </div>
      <div class="pt-2 pl-1">
        <h6>{{ selectedSurveyHeader.instructions }}</h6>
      </div>
      <div class="pt-4 pl-1">
        <button
          type="button"
          :disabled="loading"
          class="button is-primary is-rounded"
          @click.prevent="beginSurvey"
        >
          Begin Survey
        </button>
      </div>
      <Modal :toggle.sync="toggle">
        <SurveyQuestionsModal :surveyHeader="selectedSurveyHeader" />
      </Modal>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import SurveyQuestionsModal from './components/SurveyQuestionsModal'
import Modal from '@/components/Modal'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'TakeSurvey',
  components: {
    PageContentLayoutOnly,
    Modal,
    SurveyQuestionsModal
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { takeSurvey: { title: 'Take Survey' } }
    }
  }
}
</script>

<style></style>
